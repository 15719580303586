.navbar{
  background-color: #2a2b2a;
}
  
  
  
  .leftSide {
    flex: 50%;
    height: 100%;
    text-align: left;
    align-items: left;
    padding-left: 20px;
  }

  .rightSide {
    display: flex;
    width: 200;
    justify-content: space-evenly;
    height: 100%;
    padding-right: 20;
    margin-right: -200px;
    text-align: center;
  }

  .rightSide a{
    padding: 10px;
    padding-right: 30px;
    text-decoration: none;
    color: #f2f2f2;
  }

  .rightSide a:hover{
    color: #e5446d;
  }

  @media only screen   

  and (max-device-width : 600px)   {
    
  .rightSide { width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  }

 



