body{
    margin:0
}
.body-main{
    background-color: #f8f4e3;
    overflow:hidden;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Poppins';
    filter: brightness(1);
    width: 100%;
    background-image: url('../assets/background.png');
    background-size: cover;
    margin: 0;
}

.body-main-withoutbg{
    background-color: #f8f4e3;
    overflow:hidden;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Poppins';
    width: 100%;
    margin: 0;
}
.heading{
    text-align: center;
    justify-content: center;
    padding-top: 150px;
    font-size: 50px;
    color:white;
    font-weight: 900;
   
}

.mainContent{
    margin-top: 60px;
}

.intro-text{
    color:white;
    margin: -30px;
    font-size: 20px;
}

.mt-0{
   text-align: center;
   align-items: center;
   font-family: 'Lato', sans-serif;
}

.social-media{
    display: flex;
    justify-content: center;
}

.mailClass{
   margin-top: 10px;
   margin-left: 10px;
}
.instagram{
    margin-bottom: 10px;
    margin-right: 10px;
    float:left
    
}



.tiktok{
    
    float:right; }

.mail{
    
    padding: 13px;
    padding-bottom: 15px;
    border-radius: 100px;
    background-color: blue;
}

.mailIcon{
    font-size: 20px;
    color: white;
}

#mc_embed_shell{
    background-color: #F8F4E3;
    height:600px;
    margin:40px;
    border-radius:20px;
}




@media only screen   
and (min-device-width : 768px)  {
.product_offerings{
    background-color: bisque;
    width: 50%;
    height: 500px;
}
}



.form-margins{
    margin-top: 100px;
}


@media only screen   
and (min-device-width : 768px)  {

#mc_embed_signup{
    float: right;
    width: 40%;
    padding-top: 10px;
    padding-bottom:55px;
    margin:10px;
}

.contact-icon{
    float: left;
    width: 50%;
    /* background-color: #F8F4E3; */
}


.body-contact-us{
    background-color: #918659;
}
}


@media only screen   
and (max-device-width : 600px)  {

#mc_embed_signup{
    padding:35px;
    background-color: transparent;
    /* background-color: #F8F4E3; */
}

#mc_embed_shell{
    background-color: #F8F4E3;
    height:900px;
    margin:40px;
    border-radius:20px;
    border: 1px solid #373F51 ;
}


.contact-icon{
    width: 100%;
    /* background-color: #F8F4E3; */
}


.body-contact-us{
    background-color: #918659;
}
}


.about-us-section{
    height: 500px;
    text-align: center;
    align-items: center;
    justify-content: center;

    background-image: url('../assets/about_us.png');
}

.info-section{
   margin-top:30px;
   text-align: center;
   align-items: center;
}

.card-1{
    float: left;
    text-align: center;
    width: 31.3334%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #373F51;
    color: white;
    padding: 20px;
    justify-content: center;
    height: 65vh; /* Set the height of the body to the full viewport height */
    margin: 10px; /* Remove default margin */
    margin-top: 50px;
    border-radius: 20px;
}


.card-1 img {
    display:inline-block;
}

.design-section{
    margin-top:30px;
    width: 100%;
    color:white;
    padding-top: 50px;
    background-color: #373F51;
    display: inline-block;
}

.design-text{
    float: right;
    height: 80px;
    width: 60%;
    text-align:  left;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    font-size: 15px;
    font-weight:100;
    letter-spacing: 1px;
    margin-right: 20px;
}

.design-section img{
    float: left;
    width: 30%;
    margin: 30px;
}

.about_us_img{
    height: 50%;
}


@media only screen   

and (max-device-width : 600px)  {
    .body-main{
        background-color: #f8f4e3;
        overflow:hidden;
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Poppins';
        width: 100%;
    }
    
    .design-section{
        margin-top:30px;
        width: 100%;
        color:white;
        padding-top: 10px;
        background-color: #373F51;
        display: inline-block;
    }

    .design-text{
        height: 300px;
        margin: 10px;
        width: 80%;
        text-align:  left;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight:100;
        letter-spacing: 1px;
    }

    .design-section img{
        width: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-left: 100px;
    }


    .card-1{
        text-align: center;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #373F51;
        margin: 0;
        margin: 10px;
        justify-content: center;
        height: 40%; /* Set the height of the body to the full viewport height */
       
    }

    



    .about_us_img{
        height: 150px;
    }

    #intro-text{
        padding: 20px;
    }



    
    
    

}


.header-shop{
    padding:20px;
    padding-top: 100px;
    height: 250px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #373F51;
    margin-bottom: 20px;
}

.shop_us_img{
    height:80%;
    width: 40%;    padding: 10px;
    border-radius: 20px;
}


.varsity-jackets{
    float: left;
}


#mce-EMAIL, #mce-FNAME, #mce-LNAME, #mce-MMERGE6{
    background-color: #f8f4e3;
    height: 45px;
    border: 10px solid grey;
    
}

.button{
    height: 50px;
    color: yellow;
}



.offered-products{
    display: inline-flex;
    flex-direction: row;
}



/* CSS file */
.offered-products,
.offered-products-section2 {
  display: flex;
}

.product {
  display: flex;
  align-items: center; /* Vertical alignment */
  margin-right: 20px; /* Add spacing between products if needed */
}

.shop_us_img {
  border-radius: 20px; /* Add border-radius as needed */
  margin-right: 5px; /* Add spacing between image and text if needed */
  /* Add other image styles as needed */
}



.jacket, .jacket1 {
    margin-left: 1%;
    background-color: #f8f4e3;
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 1.5%;
    text-align: center;
    border: 1px solid black;
    width: 48.5%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
  }


  .jacket{
    float: right;
  }
  .jacket1{
    float:left;
  }
  
  .shop_us_img {
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  }
  
  .icon-container {
    display: flex;
    justify-content: center; /* Center the icons horizontally */
    
  }
  
  .icon-shop {
    height: 5%;
    width: 5%;
    margin: 0 5px; /* Adjust as needed */
    vertical-align: middle; /* Align the icon vertically within the line */
  }
  
  .text,
  .text1 {
    text-align: center;
    margin-top: 10px;
  }
  
  /* Additional styling if required */


  @media only screen   

and (max-device-width : 600px)  {

      .jacket{
        margin-left: 30px;
        margin-right: 35px;
      }

      .jacket1{
        margin-left: 48px;
    }

      .jacket, .jacket1 {
        
        text-align: center;
        align-items: center;
        border: 1px solid black;
        padding: 20px;
        width: 80%;
        border-radius: 20px;
        
      }

      .header-shop{
        padding:20px;
        padding-top: 50px;
        height: 150px;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #373F51;
        margin-bottom: 20px;
    }
    
      
}
  
.product-names{
    margin-bottom: 1%;
    margin-top: 2.75%;
}

.intro-faq{
    text-align: left;
    margin-left: 20px;

}

.faq-main{
    margin-left: 20px;
}

.question-section{
    background-color: #373F51;
    color: #f8f4e3;
    padding: 20px;
    border-radius: 20px;margin-right: 10px;
    margin-bottom: 20px;
}

